.contactWrapper {
    background-color: #273422;
    min-height: calc(100vh - 80px); /* Adjust based on your header height */
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;
  }
  
  .contact {
    background-color: #fff5e6; /* Cream color */
    max-width: 1000px;
    width: 100%;
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
    padding: 3rem;
    position: relative;
  }
  
  .contact h1 {
    font-size: 2.5rem;
    margin-bottom: 1.5rem;
    color: #273422;
    text-align: center;
  }
  
  .intro {
    font-size: 1.1rem;
    line-height: 1.6;
    color: #333;
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .contactInfo {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem;
  }
  
  .infoItem {
    display: flex;
    align-items: center;
    padding: 1rem;
    border-radius: 10px;
    transition: all 0.3s ease;
    cursor: pointer;
  }
  
  .infoItem:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    background-color: #f0e6d2;
  }
  
  .icon {
    color: #4caf50;
    margin-right: 1rem;
    flex-shrink: 0;
    width: 24px;
    height: 24px;
  }
  
  .infoItem h2 {
    font-size: 1.2rem;
    color: #273422;
    margin-bottom: 0.5rem;
  }
  
  .infoItem p {
    font-size: 1rem;
    color: #333;
    line-height: 1.4;
  }
  
  .notification {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #4caf50;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 1000;
  }
  
  @media (max-width: 768px) {
    .contactWrapper {
      padding: 1rem;
    }
  
    .contact {
      border-radius: 10px;
      padding: 2rem 1.5rem;
    }
  
    .contact h1 {
      font-size: 2rem;
    }
  
    .intro {
      font-size: 1rem;
    }
  
    .contactInfo {
      grid-template-columns: 1fr;
    }
  }

  /* ... (previous styles remain the same) ... */

.infoItem {
    display: flex;
    align-items: flex-start;
    padding: 1rem;
    border-radius: 10px;
    transition: all 0.3s ease;
    cursor: pointer;
  }
  
  .infoItem:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    background-color: #f0e6d2;
  }
  
  .icon {
    color: #4caf50;
    margin-right: 1rem;
    flex-shrink: 0;
    width: 24px;
    height: 24px;
  }
  
  .infoItem h2 {
    font-size: 1.2rem;
    color: #273422;
    margin-bottom: 0.5rem;
  }
  
  .infoItem p {
    font-size: 1rem;
    color: #333;
    line-height: 1.4;
  }
  
  .reservationNote {
    font-size: 0.9rem;
    color: #666;
    font-style: italic;
    margin-top: 0.5rem;
  }
  
  /* ... (remaining styles stay the same) ... */