.popupOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .popup {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
    color: #666;
  }
  
  .popup p {
    margin-bottom: 15px;
  }
  
  .popup button {
    background-color: #4caf50;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 3px;
  }
  
  .popup button:hover {
    background-color: #45a049;
  }