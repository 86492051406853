.menuHighlights {
    padding: 4rem 2rem;
    background: blanchedalmond; /* Gradient from the correct color to a slightly darker shade */
    position: relative;
    overflow: hidden;
  }
  
  .menuHighlights::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100px;
    background: linear-gradient(to bottom, rgba(39, 52, 34, 0.8), rgba(39, 52, 34, 0)); /* Fade effect using the correct color */
    pointer-events: none;
  }
  
  .title {
    text-align: center;
    font-size: 2.5rem;
    color: black;
    margin-bottom: 3rem;
    position: relative;
    z-index: 1;
  }
  
  .highlightsGrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
    z-index: 1;
  }
  
  .highlightItem {
    position: relative;
    aspect-ratio: 1 / 1;
    overflow: hidden;
    border-radius: 8px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .highlightItem:hover {
    transform: translateY(-5px);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.3);
  }
  
  .highlightImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .highlightContent {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.7);
    padding: 1rem;
    transition: background-color 0.3s ease;
  }
  
  .highlightItem:hover .highlightContent {
    background-color: rgba(0, 0, 0, 0.8);
  }
  
  .highlightTitle {
    color: white;
    font-size: 1.2rem;
    margin: 0 0 0.5rem 0;
  }
  
  .orderButton {
    background-color: #4caf50;
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.3s ease;
    z-index: 9999;
  }
  
  .orderButton:hover {
    background-color: #45a049;
  }
  
  @media (max-width: 768px) {
    .highlightsGrid {
      grid-template-columns: 1fr;
    }
  }