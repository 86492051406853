.app {
  font-family: 'Arima Madurai', cursive;
  margin: 0;
  padding: 0;
  background-color: #273422;
  color: white;
  min-height: 100vh;
}

.debugText {
  font-size: 24px;
  font-weight: bold;
}