.menuItem {
    background-color: white;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 300px;
    max-height: 370px;
    min-width: 300px;
    max-width: 300px;
  }
  
  .menuItem:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
  
  .imageContainer {
    width: 100%;
    padding-top: 60%; /* This creates a 5:3 aspect ratio */
    position: relative;
    overflow: hidden;
  }
  
  .itemImage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .placeholderImage {
    width: 100%;
    padding-top: 60%; /* Maintain the same aspect ratio as images */
    background-color: #f0f0f0; /* Light grey background */
  }
  
  .itemInfo {
    padding: 1rem;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
  
  .noImage .itemInfo {
    justify-content: flex-start;
  }
  
  .itemInfo h3 {
    margin: 0 0 0.5rem 0;
    color: #273422;
  }
  
  .itemInfo p {
    margin: 0 0 0.5rem 0;
    color: #666;
    font-size: 0.9rem;
  }
  
  .price {
    font-weight: bold;
    color: #4caf50;
    margin-top: auto;
  }
  
  .noImage .placeholderImage {
    display: none;
  }
  
  .noImage .itemInfo {
    height: 100%;
  }
  
  .noImage .itemInfo p:not(.price) {
    flex-grow: 0;
  }
  
  .noImage .price {
    margin-top: 0.5rem;
  }