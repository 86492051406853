.menuWrapper {
    background-color: #273422;
    min-height: calc(100vh - 80px);
    padding: 2rem;
  }
  
  .menu {
    max-width: 900px;
    margin: 0 auto;
    background-color: #fff5e6;
    padding: 2rem;
    border-radius: 20px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
    border-style: double;
    border-width: 10px;
    border-color: #273422;

  }
  
  .menu h1 {
    text-align: center;
    color: #273422;
    margin-bottom: 2rem;
  }
  
  .category {
    margin-bottom: 2rem;
  }
  
  .category h2 {
    color: #273422;
    margin-bottom: 1rem;
    text-align: center;
  }
  
  .grid {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr)); /* Always 2 columns */
    gap: 1rem; /* Reduced gap */
    justify-content: center;
    max-width: 620px; /* Limit max width to keep items closer */
    margin: 0 auto; /* Center the grid */
  }
  
  .gridItem {
    display: flex;
    justify-content: center;
  }
  
  @media (max-width: 768px) {
    .menuWrapper {
      padding: 1rem;
    }
  
    .menu {
      padding: 1rem;
    }
  
    .grid {
      grid-template-columns: 1fr; /* Single column for mobile */
    }
  }