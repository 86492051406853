.aboutWrapper {
  background-color: #273422;
  min-height: calc(100vh - 80px); /* Adjust based on your header height */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}

.about {
  background-color: #fff5e6; /* Cream color */
  max-width: 1000px;
  width: 100%;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
}

.blurb {
  padding: 3rem 3rem 2rem;
  text-align: center;
  color: #333;
}

.blurb h1 {
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  color: #273422;
}

.blurb p {
  font-size: 1.1rem;
  line-height: 1.8;
}

.highlight {
  color: #4caf50;
  font-weight: bold;
}

.gallery {
  width: 100%;
  height: 460px;
  overflow: hidden;
  position: relative;
}

.galleryImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 1s ease-in-out;
}

.fadeOut {
  opacity: 0;
}

@media (max-width: 768px) {
  .aboutWrapper {
    padding: 1rem;
  }

  .about {
    border-radius: 10px;
  }

  .blurb {
    padding: 2rem 1.5rem 1.5rem;
  }

  .blurb h1 {
    font-size: 2rem;
  }

  .blurb p {
    font-size: 1rem;
  }

  .gallery {
    height: 300px;
  }
}