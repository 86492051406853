.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  
.modal {
    background-color: white;
    padding: 2rem;
    border-radius: 10px;
    max-width: 500px;
    width: 90%;
    max-height: 90vh;
    overflow-y: auto;
    position: relative;
  }
  
  .closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
  }
  
  .itemImage {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 5px;
    margin-bottom: 1rem;
  }
  
  .modal h2 {
    color: #273422;
    margin-bottom: 0.5rem;
  }
  
  .modal p {
    color: #666;
    margin-bottom: 1rem;
  }
  
  .price {
    font-weight: bold;
    color: #4caf50;
    margin-bottom: 1rem;
  }
  
  .customization {
    margin-bottom: 1rem;
  }
  
  .customization h3 {
    font-size: 1rem;
    color: #273422;
    margin-bottom: 0.5rem;
  }
  
  .textInput,
  .selectInput {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
  }
  
  .addToCartButton {
    background-color: #4caf50;
    color: white;
    border: none;
    padding: 0.75rem 1rem;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    width: 100%;
    transition: background-color 0.3s ease;
  }
  
  .addToCartButton:hover {
    background-color: #45a049;
  }

  .checkboxGroup {
    display: flex;
    flex-direction: column;
  }
  
  .checkboxLabel {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
    font-size: 1rem;
    color: #666;
  }
  
  .checkboxLabel input {
    margin-right: 0.5rem;
  }

  .modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background-color: white;
  padding: 2rem;
  border-radius: 10px;
  max-width: 500px;
  width: 90%;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.modalImageContainer {
    width: 100%;
    height: 300px;
    overflow: hidden;
    border-radius: 5px;
    margin-bottom: 1rem;
  }
  

  .modalItemImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

.modal h2 {
  color: #273422;
  margin-bottom: 0.5rem;
}

.modal p {
  color: #666;
  margin-bottom: 1rem;
}

.price {
  font-weight: bold;
  color: #4caf50;
  margin-bottom: 1rem;
}