.hero {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 5%;
  background-color: #273422;
  min-height: 60vh;
}

.heroContent {
  flex: 1;
  color: white;
  max-width: 50%;
}

.heroTitle {
  font-size: 3rem;
  margin-bottom: 1.5rem;
  line-height: 1.2;
}

.greenText {
  color: #4caf50;
}

.menuButton {
  display: inline-block;
  border: 2px solid white;
  color: white;
  padding: 0.75rem 2rem;
  text-decoration: none;
  font-size: 1.2rem;
  transition: all 0.3s ease;
  background-color: transparent;
}

.menuButton:hover {
  background-color: white;
  color: #273422;
}

.heroImageContainer {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 50%;
}

.heroImage {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

@media (max-width: 1024px) {
  .hero {
    flex-direction: column;
    text-align: center;
    padding: 2rem;
  }

  .heroContent, .heroImageContainer {
    max-width: 100%;
  }

  .heroContent {
    margin-bottom: 2rem;
  }

  .heroTitle {
    font-size: 2.5rem;
  }
}

@media (max-width: 768px) {
  .heroTitle {
    font-size: 2rem;
  }
}

@media (max-width: 480px) {
  .heroTitle {
    font-size: 1.5rem;
  }
}