.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 2rem;
  background-color: #273422;
  color: white;
}

.logoContainer {
  flex: 0 0 auto;
  margin-right: 2rem;
}

.logo {
  height: 40px;
  width: auto;
}

.nav {
  flex: 1;
  display: flex;
  justify-content: flex-start;
}

.navList {
  display: flex;
  list-style-type: none;
  gap: 1.5rem;
  margin: 0;
  padding: 0;
  align-items: center;
}

.navList li a {
  color: white;
  text-decoration: none;
  font-size: 1.1rem;
  font-weight: 500;
  transition: color 0.3s ease;
}

.navList li a:hover {
  color: #e0e0e0;
}

.orderNowContainer {
  position: relative;
}

.orderNowButton {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  font-size: 1.1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-family: 'Arima Madurai', cursive;
}

.orderNowButton:hover {
  background-color: #45a049;
}

.dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  z-index: 10;
  min-width: 117.5px;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-10px);
  transition: opacity 0.3s ease, transform 0.3s ease, visibility 0s linear 0.3s;
}

.dropdown.show {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
  transition: opacity 0.3s ease, transform 0.3s ease, visibility 0s linear;
}

.dropdownItem {
  display: block;
  width: 99%;
  padding: 0.5rem 1rem;
  color: #333;
  text-decoration: none;
  transition: background-color 0.3s ease;
  text-align: left;
  border: none;
  background: none;
  font-size: 1rem;
  cursor: pointer;
  font-family: 'Arima Madurai', cursive;
}

.dropdownItem:hover {
  background-color: #f5f5f5;
}

.headerActions {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.findRestaurantButton {
  background-color: transparent;
  border: none;
  color: white;
  font-size: 1rem;
  cursor: pointer;
  transition: color 0.3s ease;
  font-family: 'Arima Madurai', cursive;
  padding: 0;
  margin-top: -2px;
}

.findRestaurantButton:hover {
  color: #e0e0e0;
  text-decoration: underline;
}

.cartIcon {
  cursor: pointer;
}

.cartIcon svg {
  transition: color 0.3s ease;
}

.cartIcon:hover svg {
  color: #e0e0e0;
}

.menuButton {
  display: none;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  z-index: 1001;
}

.mobileNav {
  display: none;
}

.cartIconMobile {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: white;
  cursor: pointer;
}

@media (max-width: 768px) {
  .header {
      flex-wrap: wrap;
  }

  .logoContainer {
      margin-right: auto;
  }

  .nav, .headerActions {
      display: none;
  }

  .menuButton {
      display: block;
  }

  .mobileNav {
      display: block;
      position: fixed;
      top: 0;
      right: -100%;
      width: 70%;
      height: 100vh;
      background-color: #273422;
      transition: right 0.3s ease;
      z-index: 1000;
      overflow-y: auto;
      padding-top: 60px;
  }

  .mobileNav.open {
      right: 0;
  }

  .mobileNav .navList {
      flex-direction: column;
      align-items: flex-start;
      padding: 2rem;
  }

  .mobileNav .navList li {
      margin-bottom: 1rem;
      width: 100%;
  }

  .orderNowContainer {
      width: 100%;
  }

  .orderNowButton {
      width: 100%;
      text-align: left;
      background-color: transparent;
      color: white;
      padding: 0;
      font-size: inherit;
  }

  .dropdown {
      position: static;
      opacity: 1;
      visibility: visible;
      transform: none;
      transition: none;
      box-shadow: none;
      border: none;
      background-color: transparent;
      padding-left: 1rem;
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.3s ease;
  }

  .dropdown.show {
      max-height: 100px;
  }

  .dropdownItem {
      color: white;
      padding: 0.5rem 0;
  }

  .dropdownItem:hover {
      background-color: rgba(255, 255, 255, 0.1);
  }

  .findRestaurantButton {
      width: 100%;
      text-align: left;
      padding: 0.5rem 0;
      margin-top: 0;
  }

  .cartIconMobile {
      padding: 0.5rem 0;
  }
}